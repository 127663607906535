import { datadogRum } from "@datadog/browser-rum";
import type { useStore } from "jotai";
import { getStaticGateValueAtom } from "@/features/FeatureFlags/atoms/callbacks";
import {
  statsigStaticGates,
  type StatsigStaticGate,
} from "@/features/FeatureFlags/flags/types";
import {
  getStaticGateCookies,
  getStaticGateKeys,
  setStaticGateCookie,
} from "@/features/FeatureFlags/helpers/staticGates/cookies";
import { getKeysExtendType } from "@/helpers/typeHelpers";

/**
 * A function that syncs the static gate cookies for a given gate. More specifically, it checks if
 * there's a query param override and syncs it with its cookie; if there's no query param override,
 * it checks the feature flag. In either case, if the effective value of the gate has changed, it
 * updates the corresponding cookie.
 *
 * @returns Whether the effective value of the gate changed
 */
const syncCookiesForStaticGate = (gate: StatsigStaticGate) => {
  try {
    const { queryParamName, effectiveValueCookieName, queryParamsCookieName } =
      getStaticGateKeys(gate);

    const { effectiveValue, queryParamOverride, gateEvaluation } =
      getStaticGateCookies(gate);
    const params = new URLSearchParams(window.location.search);

    // Always prioritize the query param value
    const queryParam = params.get(queryParamName);
    if (queryParam != null) {
      const computedGateValue = queryParam === "true" || queryParam === "";
      if (computedGateValue !== effectiveValue) {
        setStaticGateCookie(effectiveValueCookieName, computedGateValue);
        setStaticGateCookie(queryParamsCookieName, computedGateValue);
        return true;
      }

      return false;
    }

    // Then check if query param was set already
    if (queryParamOverride != null) {
      if (queryParamOverride !== effectiveValue) {
        setStaticGateCookie(effectiveValueCookieName, queryParamOverride);
        return true;
      }

      return false;
    }

    // Finally, check the feature flag
    if (gateEvaluation != null) {
      if (gateEvaluation !== effectiveValue) {
        setStaticGateCookie(effectiveValueCookieName, gateEvaluation);
        return true;
      }
    }

    return false;
  } catch (error) {
    datadogRum.addError(
      new Error(`Error syncing static gate cookies for "${gate}"`, {
        cause: error,
      }),
    );
    return false;
  }
};

/**
 * A function that syncs the static gate cookies for all static gates and reloads the page if
 * necessary.
 *
 * @returns Whether the page was reloaded
 */
export const syncCookiesForAllStaticGatesAndReloadIfNeeded = () => {
  let shouldReload = false;
  for (const gate of getKeysExtendType(statsigStaticGates)) {
    const effectiveValueChanged = syncCookiesForStaticGate(gate);
    if (statsigStaticGates[gate].shouldTriggerReload && effectiveValueChanged) {
      shouldReload = true;
    }
  }

  if (shouldReload) {
    window.location.reload();
  }

  return shouldReload;
};

type Store = ReturnType<typeof useStore>;

/**
 * A function that initializes the static gate cookies from the statsig client, setting the gate
 * evaluation and effective value cookies. This must be called exactly once after the statsig client
 * is initialized and the user is identified.
 */
export const initializeStaticGatesFromClient = (store: Store) => {
  const getStaticGateValueFromClient = store.get(getStaticGateValueAtom);
  for (const gate of getKeysExtendType(statsigStaticGates)) {
    const gateValue = getStaticGateValueFromClient(gate, { source: "client" });

    const { gateEvaluationCookieName } = getStaticGateKeys(gate);

    setStaticGateCookie(gateEvaluationCookieName, gateValue);
    // we can't set effectiveValueCookieName here because we might run into issues with any BE
    // usages of the cookie — e.g. the R18 reverse proxy would suddenly start to serve R18 assets in
    // a session that started with R17
  }
};
