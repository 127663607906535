import { isChromatic } from "@brexhq/metal-utils";
import type { StatsigClient } from "@statsig/js-client";
import { useAtomValue } from "jotai";
import { useCallback } from "react";
import { useStatsigFeatureFlagTracker } from "@/features/DevPanel/hooks/useStatsigFeatureFlagTracker";
import {
  getDynamicConfigValueAtom,
  getExperimentValueAtom,
  getGateValueAtom,
  getLayerValueAtom,
  getStaticGateValueAtom,
} from "@/features/FeatureFlags/atoms/callbacks";
import { statsigClientAtom } from "@/features/FeatureFlags/atoms/featureFlagClientAtoms";
import { createMockableHook } from "@/helpers/createMockableHook";
import { noOp } from "@/helpers/noOp";

function _useFlagClient() {
  // only track feature flags in non-prod environments
  let trackFlag = useStatsigFeatureFlagTracker();
  if (process.env.APP_ENV === "prod" || isChromatic()) {
    trackFlag = noOp;
  }

  const untrackedGetGateValue = useAtomValue(getGateValueAtom);
  /**
   * Return the value of a gate (boolean feature flag).
   * - If the flag is overridden in the dev panel, return the overridden value.
   */
  const getGateValue = useCallback<typeof untrackedGetGateValue>(
    (name, options): boolean => {
      trackFlag(name, "gate", options?.ignoreInFeatureFlagTracker ?? false);
      return untrackedGetGateValue(name, options);
    },
    [untrackedGetGateValue, trackFlag],
  );

  const untrackedGetStaticGateValue = useAtomValue(getStaticGateValueAtom);
  /**
   * Return the value of a gate (boolean feature flag).
   * - If the flag is overridden in the dev panel, return the overridden value.
   */
  const getStaticGateValue = useCallback<typeof untrackedGetStaticGateValue>(
    (name, options): boolean => {
      trackFlag(name, "gate", options?.ignoreInFeatureFlagTracker ?? false);
      return untrackedGetStaticGateValue(name, options);
    },
    [trackFlag, untrackedGetStaticGateValue],
  );

  const untrackedGetDynamicConfigValue = useAtomValue(
    getDynamicConfigValueAtom,
  );
  /**
   * Return the value of a dynamic config (non-boolean feature flag).
   * - If the flag is overridden in the dev panel, return the overridden value.
   * - If the client is not initialized, return the default value.
   */
  const getDynamicConfigValue = useCallback<
    typeof untrackedGetDynamicConfigValue
  >(
    (name, defaultValue, options) => {
      trackFlag(
        name,
        "dynamicConfig",
        options?.ignoreInFeatureFlagTracker ?? false,
      );
      return untrackedGetDynamicConfigValue(name, defaultValue, options);
    },
    [trackFlag, untrackedGetDynamicConfigValue],
  );

  const untrackedGetExperimentValue = useAtomValue(getExperimentValueAtom);
  const getExperimentValue = useCallback<typeof untrackedGetExperimentValue>(
    (name, defaultValue, options) => {
      trackFlag(
        name,
        "experiment",
        options?.ignoreInFeatureFlagTracker ?? false,
      );
      return untrackedGetExperimentValue(name, defaultValue, options);
    },
    [trackFlag, untrackedGetExperimentValue],
  );

  const untrackedGetLayerValue = useAtomValue(getLayerValueAtom);
  const getLayerValue = useCallback<typeof untrackedGetLayerValue>(
    (name, defaultValue, options) => {
      trackFlag(name, "layer", options?.ignoreInFeatureFlagTracker ?? false);
      return untrackedGetLayerValue(name, defaultValue, options);
    },
    [trackFlag, untrackedGetLayerValue],
  );

  const client = useAtomValue(statsigClientAtom);
  const logEvent = useCallback<StatsigClient["logEvent"]>(
    (...args) => client?.logEvent(...args),
    [client],
  );

  return {
    getGateValue,
    getStaticGateValue,
    getDynamicConfigValue,
    getExperimentValue,
    getLayerValue,
    logEvent,
  };
}

export const [useFlagClient, MockUseFlagClientProvider] =
  createMockableHook(_useFlagClient);

export type {
  FeatureGateOptions,
  StaticFeatureGateOptions,
  DynamicConfigOptions,
  ExperimentOptions,
  LayerOptions,
  GateValueGetter,
} from "@/features/FeatureFlags/atoms/callbacks";
