import type { StatsigClient } from "@statsig/js-client";
import { atom } from "jotai";
import type { FeatureFlagValue } from "@/features/FeatureFlags/sharedTypes";

/** Statsig client */
export const statsigClientAtom = atom<StatsigClient | null>(null);

/** Current user ID for feature flag evaluation */
export const featureFlagUserIdAtom = atom<string | null>(null);

/**
 * In-memory cache of flag values
 * Statsig queues exposures for each evaluation, this process can be slow and add latency to the UI.
 * We can use this cache to get the flag value synchronously if it's already been evaluated.
 * */
export const featureFlagCacheAtom = atom<{ [flag: string]: FeatureFlagValue }>(
  {},
);
