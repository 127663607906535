import type {
  StatsigGate,
  StatsigDynamicConfig,
  StatsigLayer,
  StatsigExperiment,
} from "@/features/FeatureFlags/flags/types";
import type {
  FeatureGateOptions,
  DynamicConfigOptions,
  LayerOptions,
  ExperimentOptions,
} from "@/features/FeatureFlags/hooks/useFlagClient";
import { useFlagClient } from "@/features/FeatureFlags/hooks/useFlagClient";
import type { FeatureFlagObject } from "@/features/FeatureFlags/sharedTypes";

export const useGateValue = (
  name: StatsigGate,
  options?: FeatureGateOptions,
) => {
  const { getGateValue } = useFlagClient();
  return getGateValue(name, options);
};

export const useDynamicConfigValue = <T extends FeatureFlagObject>(
  name: StatsigDynamicConfig,
  defaultValue: T,
  options?: DynamicConfigOptions,
) => {
  const { getDynamicConfigValue } = useFlagClient();
  return getDynamicConfigValue(name, defaultValue, options);
};

export const useExperimentValue = <T extends FeatureFlagObject>(
  name: StatsigExperiment,
  defaultValue: T,
  options?: ExperimentOptions,
) => {
  const { getExperimentValue } = useFlagClient();
  return getExperimentValue(name, defaultValue, options);
};

export const useLayerValue = <T extends FeatureFlagObject>(
  name: StatsigLayer,
  defaultValue: T,
  options?: LayerOptions,
) => {
  const { getLayerValue } = useFlagClient();
  return getLayerValue(name, defaultValue, options);
};
