/**
 * Legacy Statsig flags created before the migration from LaunchDarkly to Statsig.
 * These should ideally be moved to their relevant domains.
 */

export const legacyGates = [
  "25q1-dashboard-admin-home",
  "25q1-custom-roles-team-domain",
  "accounting_ftux_qbo_setup",
  "empower-accounting-export-history-m2",
  "bba_only_bill_pay_experiment",
  "co_browsing_gate",
  "compliance_budget_guide_feature_gate",
  "compliance_budget_guide_feature_gate",
  "proof_of_funds",
  "rewards_remove_crypto",
  "user-groups-enabled",
  "enable-maintenance-page",
  "travel-preload-spotnana-oauth-token",
  "language-switcher",
  "banking-demo",
  "enable-lending-disclosure",
] as const;

export const legacyDynamicConfigs = [] as const;

export const legacyExperiments = [
  "accounting_ftux_skip_qbo_bank_feed",
  "bill_pay_bba_growth",
  "compliance_deadline_upsells_experiment",
  "example-feature-dashboard",
  "kyc_pup_collection",
  "new_irs_letter_upload",
  "referrals_conversion",
] as const;
