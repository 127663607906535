import { isChromatic } from "@brexhq/metal-utils";
import { atom, useAtomValue } from "jotai";
import { useMemo } from "react";
import { useFeatureFlagTracker } from "@/features/DevPanel/hooks/useFeatureFlagTracker";
import { featureFlagsAtom } from "@/features/FeatureFlags/atoms/featureFlagsAtoms";
import type { FeatureFlag } from "@/features/FeatureFlags/helpers/allFeatureFlags";
import type { CamelCase } from "@/features/FeatureFlags/sharedTypes";

type UseFeatureFlagsOptions = {
  ignoreInFeatureFlagTracker?: boolean;
};

/**
 * @deprecated
 * Please use the useFlagClient hook for new Statsig flags.
 * A hook to retrieve feature flag values.
 *
 * @returns An object with `camelCased` feature flag keys and their values.
 *
 * @docs [Confluence - Using feature flags](https://brexhq.atlassian.net/wiki/spaces/ENGDOC/pages/468091901/Using+feature+flags)
 *
 * @example
 * const {
 *   myFeatureFlag, // "my-feature-flag"
 *   anotherFeatureFlag, // "another-feature-flag"
 * } = useFeatureFlags();
 */
export const useFeatureFlags = ({
  ignoreInFeatureFlagTracker,
}: UseFeatureFlagsOptions = {}) => {
  const featureFlags = useAtomValue(featureFlagsAtom);
  if (process.env.APP_ENV === "prod" || isChromatic()) {
    return featureFlags;
  }

  // Disabling rule-of-hooks here because it won't actually cause a problem.
  // APP_ENV should never change while a component is mounted, which means that
  // hooks will always be called in the same order on every render, which is
  // what the rule of hooks is intended to ensure.
  // eslint-disable-next-line react-compiler/react-compiler -- FIXME:  Hooks must be called in the exact same order in every component render, this one is called conditionally. This will prevent the react-compiler from optimizing the component, address the issue in the line below
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useFeatureFlagTracker(featureFlags, ignoreInFeatureFlagTracker);
};

/**
 * @deprecated
 * Please use the useFlagClient hook for new Statsig flags.
 * A hook to retrieve a single feature flag value.
 */
export const useFeatureFlag = <Flag extends CamelCase<FeatureFlag>>(
  flag: Flag,
  { ignoreInFeatureFlagTracker }: UseFeatureFlagsOptions = {},
) => {
  const featureFlagAtom = useMemo(
    () => atom((get) => get(featureFlagsAtom)[flag]),
    [flag],
  );

  const featureFlag = useAtomValue(featureFlagAtom);

  if (process.env.APP_ENV === "prod" || isChromatic()) {
    return featureFlag;
  }

  // Disabling rule-of-hooks here because it won't actually cause a problem.
  // APP_ENV should never change while a component is mounted, which means that
  // hooks will always be called in the same order on every render, which is
  // what the rule of hooks is intended to ensure.
  // eslint-disable-next-line react-compiler/react-compiler -- FIXME:  Hooks must be called in the exact same order in every component render, this one is called conditionally. This will prevent the react-compiler from optimizing the component, address the issue in the line below
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useFeatureFlagTracker(
    { [flag]: featureFlag },
    ignoreInFeatureFlagTracker,
  )[flag];
};
