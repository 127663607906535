import type {
  FeatureFlagsSet,
  FeatureFlag,
} from "@/features/FeatureFlags/helpers/allFeatureFlags";
import { allFeatureFlags } from "@/features/FeatureFlags/helpers/allFeatureFlags";
import type { CamelCasedProperties } from "@/features/FeatureFlags/sharedTypes";

/**
 * Converts a kebab-case string to camelCase
 * @param featureFlag Kebab-case feature flag name
 */
export const camelize = (featureFlag: FeatureFlag): CamelizedFeatureFlag =>
  featureFlag
    .split(/[._-]/)
    .map((part, index) => {
      if (part === "") {
        return "";
      }
      return index > 0
        ? `${part[0].toLocaleUpperCase()}${part.slice(1).toLocaleLowerCase()}`
        : part.toLocaleLowerCase();
    })
    .join("") as CamelizedFeatureFlag;

export type CamelizedFeatureFlagSet = CamelCasedProperties<FeatureFlagsSet>;
export type CamelizedFeatureFlag = keyof CamelizedFeatureFlagSet;

/**
 * Mapping of feature flag names and their corresponding cases for quick lookup
 *
 * Example:
 *
 * {
 *   "test_domain_name.zzzz-test-1-do-2-not-3-remove": "testDomainNameZzzzTest1Do2Not3Remove",
 *   "testDomainNameZzzzTest1Do2Not3Remove": "test_domain_name.zzzz-test-1-do-2-not-3-remove",
 * }
 */
export const casedFeatureFlagsMap = allFeatureFlags.reduce(
  (casedFlags, [flag]) => ({
    ...casedFlags,
    [flag]: camelize(flag),
    [camelize(flag)]: flag,
  }),
  {} as { [key in FeatureFlag | CamelizedFeatureFlag]: string },
);
