/**
 * DO NOT ADD INDIVIDUAL FLAG NAMES TO THIS FILE.
 * Please add them to their corresponding domain file
 * (creating one if necessary) and then import them here.
 */
import {
  legacyGates,
  legacyDynamicConfigs,
  legacyExperiments,
} from "./domains/legacy";
import { onboardingGates } from "@/domains/Onboarding/featureFlags";
import {
  primitivesGates,
  primitivesDynamicConfigs,
  primitivesStaticGates,
} from "@/domains/Primitives/shared/featureFlags";
import type { StaticGateMetadataMap } from "@/features/FeatureFlags/sharedTypes";
import { bankingGates } from "@/routes/Accounts/Banking/featureFlags";
import { bankingDynamicConfigs } from "@/routes/AccountsLegacy/Banking/featureFlags";

/**
 * A type representing the state of all flags of various types from Statsig.
 */
export type StatsigFlagState = {
  gate: StatsigGate[];
  dynamicConfig: StatsigDynamicConfig[];
  experiment: StatsigExperiment[];
  layer: StatsigLayer[];
};

/**
 * A type consisting of the various types of flags from Statsig.
 */
export type StatsigFlagType = keyof StatsigFlagState;

/**
 * A list of all gates used in the app.
 */
export const statsigGates = [
  ...bankingGates,
  ...legacyGates,
  ...onboardingGates,
  ...primitivesGates,
  "gsa_demo_settings",
] as const;

/**
 * A type consisting of the names of all gates used in the app.
 */
export type StatsigGate = (typeof statsigGates)[number];

/**
 * A list of all static gates used in the app. Static gates are those that are evaluated once
 */
export const statsigStaticGates = {
  ...primitivesStaticGates,
} as const satisfies StaticGateMetadataMap;

/**
 * A type consisting of the names of all static gates used in the app.
 */
export type StatsigStaticGate = keyof typeof statsigStaticGates;

/**
 * A list of all dynamic configs used in the app.
 */
export const statsigDynamicConfigs = [
  ...legacyDynamicConfigs,
  ...primitivesDynamicConfigs,
  ...bankingDynamicConfigs,
] as const;

/**
 * A type consisting of the names of all dynamic configs used in the app.
 */
export type StatsigDynamicConfig = (typeof statsigDynamicConfigs)[number];

/**
 * A list of all experiments used in the app.
 */
export const statsigExperiments = [...legacyExperiments] as const;

/**
 * A type consisting of the names of all experiments used in the app.
 */
export type StatsigExperiment = (typeof statsigExperiments)[number];

/**
 * A list of all layers used in the app.
 */
export const statsigLayers = [] as const;

/**
 * A type consisting of the names of all experiments used in the app.
 */
export type StatsigLayer = (typeof statsigLayers)[number];

/**
 * A type consisting of the names of all flags used in the app.
 */
export type StatsigFlag =
  | StatsigGate
  | StatsigStaticGate
  | StatsigDynamicConfig
  | StatsigExperiment
  | StatsigLayer;
