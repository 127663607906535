import type { StatsigUser } from "@statsig/js-client";
import type { FilteredUserProperties } from "@/features/Analytics/helpers/filterUserProperties";
import { decodeEntityId } from "@/helpers/graphql";

const STATSIG_PRIVATE_ATTRIBUTES = ["email"] as const;

const STATSIG_BUILT_IN_PROPERTIES = [
  "appVersion",
  "country",
  "ip",
  "locale",
  "userAgent",
] as const;

type StatsigBuiltInUserProperties = Pick<
  StatsigUser,
  (typeof STATSIG_BUILT_IN_PROPERTIES)[number]
>;

const isPrivateStatsigAttribute = (userPropertyKey: string) =>
  (STATSIG_PRIVATE_ATTRIBUTES as ReadonlyArray<string>).includes(
    userPropertyKey,
  );

const isBuiltinStatsigProperty = (userPropertyKey: string) =>
  (STATSIG_BUILT_IN_PROPERTIES as ReadonlyArray<string>).includes(
    userPropertyKey,
  );

/**
 * Transform a user ID and combined set of Statsig and custom user
 * properties into a unified Statsig user object.
 *
 * This function employs an optimization to reduce the number of billable
 * anonymous users.
 */
export const toStatsigUser = (
  userId: string,
  userProperties: FilteredUserProperties & {
    anonymous?: boolean;
    email?: string;
  },
): StatsigUser => {
  const user: StatsigUser = {
    custom: {},
    customIDs: {},
    privateAttributes: {},
  };

  if (userProperties.anonymous) {
    // if the user is anonymous, we should not set the userID
    // this will cause the statsig SDK to create/use a stable ID it generates
    user.custom = { ...user.custom, anonymousId: userId };
  } else {
    // try to decode the user ID
    let statsigUserID = userId;
    try {
      const decodedId = decodeEntityId(userId);
      if (decodedId && decodedId.typename === "User") {
        statsigUserID = decodedId.rawId;
      }
    } catch {
      // do nothing
    }
    user.userID = statsigUserID;
  }

  for (const key in userProperties) {
    if (isPrivateStatsigAttribute(key) && user.privateAttributes) {
      user.privateAttributes[key as keyof FilteredUserProperties] =
        userProperties[key as keyof FilteredUserProperties]?.toString();
    } else if (isBuiltinStatsigProperty(key)) {
      user[key as keyof StatsigBuiltInUserProperties] =
        userProperties[key as keyof FilteredUserProperties]?.toString();
    } else if (key === "companyId") {
      let statsigAccountID = userProperties[key];
      try {
        // try to decode the ID
        const decodedId = decodeEntityId(userProperties[key]);
        if (decodedId && decodedId.typename === "Account") {
          statsigAccountID = decodedId.rawId;
        }
      } catch {
        // do nothing
      }

      // we should be targeting from customIDs for this
      // but add it to custom as well for legacy use cases
      user.custom = {
        ...user.custom,
        companyId: statsigAccountID,
      };
      user.customIDs = { ...user.customIDs, accountID: statsigAccountID };
    } else {
      user.custom = {
        ...user.custom,
        [key]: userProperties[key as keyof FilteredUserProperties],
      };
    }
  }

  return user;
};
