import { StatsigClient } from "@statsig/js-client";
import { useAtom, useSetAtom, useStore } from "jotai";
import * as React from "react";
import type { FilteredUserProperties } from "@/features/Analytics/helpers/filterUserProperties";
import {
  featureFlagCacheAtom,
  featureFlagUserIdAtom,
  statsigClientAtom,
} from "@/features/FeatureFlags/atoms/featureFlagClientAtoms";
import { initializeStaticGatesFromClient } from "@/features/FeatureFlags/helpers/staticGates/setup";
import { toStatsigUser } from "@/features/FeatureFlags/helpers/toStatsigUser";
import { getEnvironment } from "@/helpers/environment";
import { internalTrackError } from "@/helpers/errorTracking";

/**
 * Timeouts (in milliseconds) for auto resolving the Statsig init/identify calls
 */
const FEATURE_FLAG_CACHE_TIMEOUT = 1500;
const FEATURE_FLAG_NO_CACHE_TIMEOUT = 3000;

/**
 * Mapping of app environment names to Statsig environment names
 */
const STATSIG_APP_ENV_MAP = {
  prod: "production",
  stg: "staging",
  dev: "staging",
  localhost: "development",
};

export const useFeatureFlagInitialization = () => {
  const [statsigClient, setStatsigClient] = useAtom(statsigClientAtom);
  const [featureFlagUserId, setFeatureFlagUserId] = useAtom(
    featureFlagUserIdAtom,
  );
  const setFeatureFlagCache = useSetAtom(featureFlagCacheAtom);

  /**
   * Shut down the Statsig client
   */
  const shutdown = React.useCallback(async () => {
    await statsigClient?.shutdown();
  }, [statsigClient]);

  const initialize = React.useCallback(
    async (anonymousId: string): Promise<void> => {
      // do not initialize if we already have a client
      if (!statsigClient) {
        const newStatsigClient = new StatsigClient(
          getEnvironment("STATSIG_API_KEY") as string,
          {
            custom: {
              anonymousId: anonymousId,
            },
          },
          {
            networkConfig: {
              api: "https://dashboard.brex.com/fg/v1",
            },
            environment: {
              tier: STATSIG_APP_ENV_MAP[
                getEnvironment("APP_ENV") as
                  | "prod"
                  | "stg"
                  | "dev"
                  | "localhost"
              ],
            },
          },
        );
        try {
          await newStatsigClient.initializeAsync({
            timeoutMs: FEATURE_FLAG_CACHE_TIMEOUT,
          });
        } catch (error) {
          internalTrackError({ error });
        }
        setStatsigClient(newStatsigClient);
      }
    },
    [setStatsigClient, statsigClient],
  );

  const store = useStore();
  const identify = React.useCallback(
    async (
      userId: string,
      userProperties?: FilteredUserProperties & {
        anonymous?: boolean;
        email?: string;
      },
    ): Promise<void> => {
      try {
        if (statsigClient) {
          const statsigUser = toStatsigUser(userId, userProperties ?? {});

          // try to get the latest values from the network, waiting longer if we don't have cached values
          let timeoutMs = FEATURE_FLAG_NO_CACHE_TIMEOUT;
          if (statsigClient.dataAdapter.getDataSync(statsigUser) !== null) {
            timeoutMs = FEATURE_FLAG_CACHE_TIMEOUT;
          }
          await statsigClient.updateUserAsync(statsigUser, {
            timeoutMs: timeoutMs,
          });
          setStatsigClient({ ...statsigClient } as StatsigClient);
          setFeatureFlagCache({});
          setFeatureFlagUserId(userId);
          initializeStaticGatesFromClient(store);
        }
      } catch (error) {
        internalTrackError({ error });
      }
    },
    [
      setFeatureFlagCache,
      setFeatureFlagUserId,
      setStatsigClient,
      statsigClient,
      store,
    ],
  );

  return {
    shutdown,
    initialize,
    identify,
    statsigClient,
    userId: featureFlagUserId,
  };
};
