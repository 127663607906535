import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { featureFlagUserIdAtom } from "./featureFlagClientAtoms";
import type { FeatureFlagValue } from "@/features/FeatureFlags/sharedTypes";

interface FeatureFlagOverridesState {
  [userId: string]: {
    [flag: string]: FeatureFlagValue;
  };
}

type FeatureFlagOverridesAction =
  | {
      type: "add";
      flagName: string;
      value: FeatureFlagValue;
    }
  | {
      type: "remove";
      flagName: string;
    }
  | {
      type: "removeAll";
    };

/**
 * Overrides for feature flags in development, stores overrides for each user ID
 **/
export const allFlagOverridesAtom = atomWithStorage<FeatureFlagOverridesState>(
  "FEATURE_FLAG_DEV_OVERRIDES",
  {},
);

const namespacedOverridesAtom = atom((get) => {
  const allOverrides = get(allFlagOverridesAtom);
  const namespaceKey = get(featureFlagUserIdAtom) || "";
  return {
    ...(allOverrides[namespaceKey] || {}),
  };
});

/**
 * Overrides for feature flags in development for the current user ID
 **/
export const featureFlagOverridesAtom = atom<
  FeatureFlagOverridesState[string],
  [FeatureFlagOverridesAction],
  void
>(
  (get) => get(namespacedOverridesAtom),
  (get, set, action) => {
    const allOverrides = get(allFlagOverridesAtom);
    const namespaceKey = get(featureFlagUserIdAtom) || "";
    const namespacedOverrides = get(namespacedOverridesAtom);

    if (action.type === "add") {
      const updatedOverrides = {
        ...allOverrides,
        [namespaceKey]: {
          ...namespacedOverrides,
          [action.flagName]: action.value,
        },
      };

      set(allFlagOverridesAtom, updatedOverrides);
    }

    if (action.type === "remove") {
      const { [action.flagName]: _, ...rest } = namespacedOverrides;
      const updatedOverrides = {
        ...allOverrides,
        [namespaceKey]: rest,
      };

      set(allFlagOverridesAtom, updatedOverrides);
    }

    if (action.type === "removeAll") {
      const { [namespaceKey]: _, ...rest } = allOverrides;

      set(allFlagOverridesAtom, rest);
    }
  },
);
