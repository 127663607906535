import { datadogRum } from "@datadog/browser-rum";
import type {
  FeatureGateEvaluationOptions,
  DynamicConfigEvaluationOptions,
  ExperimentEvaluationOptions,
  LayerEvaluationOptions,
} from "@statsig/client-core";
import { atom, getDefaultStore } from "jotai";
import {
  featureFlagCacheAtom,
  statsigClientAtom,
} from "@/features/FeatureFlags/atoms/featureFlagClientAtoms";
import { featureFlagOverridesAtom } from "@/features/FeatureFlags/atoms/featureFlagOverridesAtoms";
import type {
  StatsigDynamicConfig,
  StatsigExperiment,
  StatsigGate,
  StatsigLayer,
  StatsigStaticGate,
} from "@/features/FeatureFlags/flags/types";
import { isFailedEvaluation } from "@/features/FeatureFlags/helpers/isFailedEvaluation";
import { getStaticGateValueFromCookie } from "@/features/FeatureFlags/helpers/staticGates/cookies";
import type { FeatureFlagObject } from "@/features/FeatureFlags/sharedTypes";

type CommonOptions = {
  ignoreInFeatureFlagTracker?: boolean;
};
export type FeatureGateOptions = FeatureGateEvaluationOptions & CommonOptions;
export type StaticFeatureGateOptions = FeatureGateEvaluationOptions &
  CommonOptions & {
    /**
     * If set to "client", will forcefully get the value from the statsig client. Otherwise,
     * will get the value from the cookie.
     * @default "cookie"
     */
    source?: "client" | "cookie";
  };
export type DynamicConfigOptions = DynamicConfigEvaluationOptions &
  CommonOptions;
export type ExperimentOptions = ExperimentEvaluationOptions & CommonOptions;
export type LayerOptions = LayerEvaluationOptions & CommonOptions;
export type GateValueGetter = (
  name: StatsigGate,
  options?: FeatureGateOptions,
) => boolean;

export const getDynamicConfigValueAtom = atom((get) => {
  const devOverrides = get(featureFlagOverridesAtom);
  const featureFlagCache = get(featureFlagCacheAtom);
  const client = get(statsigClientAtom);

  return function getDynamicConfigValue<T extends FeatureFlagObject>(
    name: StatsigDynamicConfig,
    defaultValue: T,
    options?: DynamicConfigOptions,
  ) {
    if (devOverrides[name] !== undefined) {
      return devOverrides[name] as T;
    }

    if (featureFlagCache[name] !== undefined) {
      return featureFlagCache[name] as T;
    }

    if (!client) {
      return defaultValue;
    }

    const dynamicConfig = client.getDynamicConfig(name, options);
    if (!isFailedEvaluation(dynamicConfig.details)) {
      featureFlagCache[name] = dynamicConfig.value as T;
    }

    return dynamicConfig.value as T;
  };
});

export const getGateValueAtom = atom((get) => {
  const devOverrides = get(featureFlagOverridesAtom);
  const featureFlagCache = get(featureFlagCacheAtom);
  const client = get(statsigClientAtom);

  return function getGateValue(
    name: StatsigGate,
    options?: FeatureGateOptions,
  ): boolean {
    if (devOverrides[name] !== undefined) {
      return devOverrides[name] as boolean;
    }

    if (featureFlagCache[name] !== undefined) {
      return featureFlagCache[name] as boolean;
    }

    if (!client) {
      return false;
    }

    const featureGate = client.getFeatureGate(name, options);
    if (!isFailedEvaluation(featureGate.details)) {
      featureFlagCache[name] = featureGate.value;
    }
    return featureGate.value;
  };
});

export const getStaticGateValueAtom = atom((get) => {
  const devOverrides = get(featureFlagOverridesAtom);
  const featureFlagCache = get(featureFlagCacheAtom);
  const client = get(statsigClientAtom);

  return function getStaticGateValue(
    name: StatsigStaticGate,
    options?: StaticFeatureGateOptions,
  ): boolean {
    if (devOverrides[name] !== undefined) {
      return devOverrides[name] as boolean;
    }

    if (featureFlagCache[name] !== undefined) {
      return featureFlagCache[name] as boolean;
    }

    // this should only ever be used by initializeStaticGatesFromClient, which needs to bypass the
    // cookie logic to get the actual value returned by statsig
    if (options?.source === "client") {
      if (!client) {
        return false;
      }

      // we do not write to the cache here because the statsig gate value might differ from the
      // effective value for the gate (e.g. considering the query param override)
      return client.getFeatureGate(name, options).value;
    }

    // FF evaluation is a hot path and getStaticGateValueFromCookie is a bit costly because we need
    // to read from cookies, but we should only ever actually call this once per gate because of the
    // featureFlagCache check above
    const evaluation = getStaticGateValueFromCookie(name);
    featureFlagCache[name] = evaluation;
    return evaluation;
  };
});

export const getExperimentValueAtom = atom((get) => {
  const devOverrides = get(featureFlagOverridesAtom);
  const client = get(statsigClientAtom);

  return function getExperimentValue<T extends FeatureFlagObject>(
    name: StatsigExperiment,
    defaultValue: T,
    options?: ExperimentOptions,
  ) {
    if (devOverrides[name] !== undefined) {
      return devOverrides[name] as T;
    }

    if (!client) {
      return defaultValue;
    }
    return client.getExperiment(name, options).value as T;
  };
});

export const getLayerValueAtom = atom((get) => {
  const devOverrides = get(featureFlagOverridesAtom);
  const client = get(statsigClientAtom);

  return function getLayerValue<T extends FeatureFlagObject>(
    name: StatsigLayer,
    defaultValue: T,
    options?: LayerOptions,
  ) {
    if (devOverrides[name] !== undefined) {
      return devOverrides[name] as T;
    }

    if (!client) {
      return defaultValue;
    }
    return client.getLayer(name, options).__value as T;
  };
});

const defaultStore = getDefaultStore();

export const getStaticGateValue = (name: StatsigStaticGate) => {
  const internalGetStaticGateValue = defaultStore.get(getStaticGateValueAtom);

  const value = internalGetStaticGateValue(name);

  datadogRum.setGlobalContextProperty(`static-gate.${name}`, value);

  return value;
};
