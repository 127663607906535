import cookies from "js-cookie";
import {
  type StatsigStaticGate,
  statsigStaticGates,
} from "@/features/FeatureFlags/flags/types";
import type { StaticGateKeys } from "@/features/FeatureFlags/sharedTypes";

export const getStaticGateKeys = (gate: StatsigStaticGate): StaticGateKeys => {
  const gateMetadata = statsigStaticGates[gate];

  if ("keys" in gateMetadata && gateMetadata.keys) {
    return gateMetadata.keys;
  }

  return {
    effectiveValueCookieName: `static-gate.effective-value.${gate}`,
    queryParamsCookieName: `static-gate.query-params.${gate}`,
    gateEvaluationCookieName: `static-gate.feature-flag.${gate}`,
    queryParamName: gate,
  };
};

export const getStaticGateValueFromCookie = (gate: StatsigStaticGate) => {
  const { effectiveValueCookieName } = getStaticGateKeys(gate);
  return (
    convertStaticGateCookieValue(cookies.get(effectiveValueCookieName)) ?? false
  );
};

export const getStaticGateCookies = (gate: StatsigStaticGate) => {
  const {
    effectiveValueCookieName,
    queryParamsCookieName,
    gateEvaluationCookieName,
  } = getStaticGateKeys(gate);

  return {
    effectiveValue: convertStaticGateCookieValue(
      cookies.get(effectiveValueCookieName),
    ),
    queryParamOverride: convertStaticGateCookieValue(
      cookies.get(queryParamsCookieName),
    ),
    gateEvaluation: convertStaticGateCookieValue(
      cookies.get(gateEvaluationCookieName),
    ),
  };
};

const convertStaticGateCookieValue = (value: string | undefined | null) => {
  if (value == null) {
    return null;
  }

  return value === "true";
};

export const setStaticGateCookie = (name: string, value: boolean) => {
  cookies.set(name, `${value === true}`, { expires: 30 /* 30 days */ });
};
