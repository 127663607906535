import { datadogRum } from "@datadog/browser-rum";
import type { StatsigClient } from "@statsig/js-client";
import { atom } from "jotai";
import Cookies from "js-cookie";
import {
  featureFlagCacheAtom,
  statsigClientAtom,
} from "@/features/FeatureFlags/atoms/featureFlagClientAtoms";
import { featureFlagOverridesAtom } from "@/features/FeatureFlags/atoms/featureFlagOverridesAtoms";
import type { FeatureFlag } from "@/features/FeatureFlags/helpers/allFeatureFlags";
import { allFeatureFlags } from "@/features/FeatureFlags/helpers/allFeatureFlags";
import { casedFeatureFlagsMap } from "@/features/FeatureFlags/helpers/camelize";
import type {
  CamelizedFeatureFlag,
  CamelizedFeatureFlagSet,
} from "@/features/FeatureFlags/helpers/camelize";
import { isFailedEvaluation } from "@/features/FeatureFlags/helpers/isFailedEvaluation";
import type { FeatureFlagValue } from "@/features/FeatureFlags/sharedTypes";
import { inSyntheticTest } from "@/helpers/environment";

/**
 * Clean the flag name for Statsig.
 * Statsig only allows alphanumeric characters, hyphens, and underscores.
 * Launchdarkly allows some other characters like periods and slashes.
 * During flag migration, we replaced those characters with underscores.
 */
export const cleanLDFlagNameForStatsig = (flagName: string) => {
  return flagName.replace(/[^a-zA-Z0-9-_ ]/g, "_");
};

const getStatsigFlag = (
  statsigClient: StatsigClient,
  flag: CamelizedFeatureFlag,
  defaultValue: FeatureFlagValue,
) => {
  const statsigFlagName = cleanLDFlagNameForStatsig(casedFeatureFlagsMap[flag]);

  if (typeof defaultValue === "boolean") {
    const gate = statsigClient.getFeatureGate(statsigFlagName);
    if (isFailedEvaluation(gate.details)) {
      return { value: defaultValue, isFailedEval: true };
    }
    return { value: gate.value, isFailedEval: false };
  }

  // non-boolean flags were migrated to dynamic configs with the relevant value under the "value" key
  const dynamicConfig = statsigClient.getDynamicConfig(statsigFlagName);
  if (isFailedEvaluation(dynamicConfig.details)) {
    return { value: defaultValue, isFailedEval: true };
  }
  return {
    value: dynamicConfig.get<typeof defaultValue>("value") as FeatureFlagValue,
    isFailedEval: false,
  };
};

/**
 * @deprecated
 * This atom stores finalized flags values from LD applying any local overrides.
 **/
export const featureFlagsAtom = atom((get) => {
  // Dev overrides are only available in non-production environments (staging, local, and dev PE), so we can skip it in prod
  const devOverrides =
    process.env.APP_ENV !== "prod" ? get(featureFlagOverridesAtom) : null;

  const statsigClient = get(statsigClientAtom);
  const featureFlagCache = get(featureFlagCacheAtom);

  const camelFlags = allFeatureFlags.reduce(
    (flagSet, [flag, defaultValue]) => ({
      ...flagSet,
      [casedFeatureFlagsMap[flag] as CamelizedFeatureFlag]: defaultValue,
    }),
    {} as CamelizedFeatureFlagSet,
  );
  return new Proxy(camelFlags, {
    get: (target, prop, receiver) => {
      /**
       * Whenever a user accesses one of the flags, proxy that request to
       * the LaunchDarkly client to get the flag value and send metrics.
       *
       * If the LaunchDarkly client returns `undefined`, it generally means
       * that the LaunchDarkly service is down and the bootstrapped values
       * in localStorage are unavailable. In this case, return the default
       * value specified in order to prevent an unexpected scenario where
       * the value doesn't match the expected type.
       *
       * As the hook that consumes this atom can re-render multiple times,
       * we are caching the value of the variation in-memory to avoid
       * requesting it multiple times which results in some performance overhead.
       * ldCache is managed in jotai atom instead of globally to support reset
       * from LD mocks util in tests.
       */
      if (prop in camelFlags) {
        const flag = prop as CamelizedFeatureFlag;
        const flagDefaultValue = camelFlags[flag];

        let flagValue: FeatureFlagValue | undefined;

        if (featureFlagCache[flag] !== undefined) {
          flagValue = featureFlagCache[flag];
        } else if (statsigClient) {
          const statsigFlag = getStatsigFlag(
            statsigClient,
            flag,
            flagDefaultValue,
          );

          flagValue = statsigFlag.value;
          if (!statsigFlag.isFailedEval) {
            featureFlagCache[flag] = flagValue;
          }
        }

        const flagEvaluation =
          getFeatureFlagFromCookie(flag) ??
          devOverrides?.[casedFeatureFlagsMap[flag] as FeatureFlag] ??
          flagValue ??
          flagDefaultValue;

        if (datadogFeatureFlagAllowlist.has(flag)) {
          datadogRum.addFeatureFlagEvaluation(flag, flagEvaluation);
          datadogRum.setGlobalContextProperty(
            `featureFlag.${flag}`,
            flagEvaluation,
          );
        }

        return flagEvaluation;
      }

      return Reflect.get(target, prop, receiver);
    },
  });
});

function getFeatureFlagFromCookie<Flag extends CamelizedFeatureFlag>(
  flag: Flag,
): CamelizedFeatureFlagSet[Flag] | undefined {
  // We only want to read FFs from cookies if 1) we're in a synthetic test and 2) we're not in prod
  if (!inSyntheticTest() || process.env.APP_ENV === "prod") {
    return undefined;
  }

  try {
    const cookie = Cookies.get(`featureFlag.ld.${flag}`);
    if (cookie) {
      return JSON.parse(cookie);
    }
  } catch {}

  return undefined;
}

/**
 * An allowlist of FFs to be added as metadata to Datadog RUM. It's meant to include FFs for the
 * Data Fetching Revamp project, as we need to compare metrics across variants for migrated routes.
 */
const datadogFeatureFlagAllowlist: ReadonlySet<CamelizedFeatureFlag> = new Set([
  "25q1DashboardArchitectureMigrationWallet",
  "enableRelayApolloSyncing",
  // without satisfies we don't get autocompletion when typing FF names
] satisfies CamelizedFeatureFlag[]);
